import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Check2faVerificationCodeReqPayload,
  TwoFaDeviceVerificationStepEnum
} from '@app/pages/my/features/settings/data-access/models/account-settings.model';
import { NgOptimizedImage } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FormErrorMessagePipe } from '@pipes/form-error-message.pipe';
import { RoutePaths } from '@utils/route.utils';
import { AuthStore } from '@app/store/auth/auth.store';
import { AccountSettingsStore } from '@app/pages/my/features/settings/store/account-settings.store';
import { VerificationOptionEnum } from '@models/auth.model';
import { filter, take } from 'rxjs';
import { CallStateEnum } from '@models/call-state.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { FormConfigsStore } from '@app/store/form-configs/form-configs.store';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-two-fa-device-verification',
  standalone: true,
  imports: [
    MatDialogTitle,
    TranslateModule,
    MatDialogContent,
    NgOptimizedImage,
    MatIcon,
    CdkCopyToClipboard,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    NgxMaskDirective,
    FormErrorMessagePipe,
    MatError,
    MatLabel,
    MatProgressSpinner,
    MatButton,
    MatDialogActions,
    MatDialogClose
  ],
  templateUrl: './two-fa-device-verification.component.html',
  styleUrl: './two-fa-device-verification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNgxMask()]
})
export class TwoFaDeviceVerificationComponent implements OnInit {
  twoFaDeviceVerificationEnum = TwoFaDeviceVerificationStepEnum;
  currentStep = signal(TwoFaDeviceVerificationStepEnum.Loading);

  contactPageUrl = RoutePaths.Core.Contact.absolutePath;

  matSnackBar = inject(MatSnackBar);
  authStore = inject(AuthStore);
  formConfigsStore = inject(FormConfigsStore);
  authService = inject(AuthService);

  #translateService = inject(TranslateService);

  verificationCodeControl = new FormControl(null);

  codeVerificationCallState$ = toObservable(
    this.authStore.codeVerificationCallState
  );

  updateDeviceStatusToVerifiedCallState$ = toObservable(
    this.authStore.updateDeviceStatusToVerifiedCallState
  );

  tfaAccountInfoCallState$ = toObservable(
    this.authStore.tfaAccountInfoCallState
  );

  errorMessage = {
    required: 'validation_device_google_auth_required',
    pattern: 'validation_device_google_auth_pattern',
    wrongSecurityCode: 'validation_device_wrong_security_code'
  };

  dialogData: {
    isLogin: boolean;
  } = inject(MAT_DIALOG_DATA);

  matDialogRef: MatDialogRef<TwoFaDeviceVerificationComponent> = inject(
    MatDialogRef<TwoFaDeviceVerificationComponent>
  );

  ngOnInit() {
    this.authStore.resetCodeVerificationCallState();

    const payload = {
      accountId: this.authStore.authUser().id,
      type: VerificationOptionEnum.App,
      jwtToken: this.authStore.authUser().token,
      isLogin: this.dialogData.isLogin
    };
    this.authStore.get2faInfo({
      payload
    });

    this.verificationCodeControl.setValidators([
      Validators.required,
      Validators.pattern(
        this.formConfigsStore.accountFormDataConfigs().deviceApp.validPattern
      )
    ]);

    this.tfaAccountInfoCallState$
      .pipe(
        filter((callState) => callState === CallStateEnum.Loaded),
        take(1)
      )
      .subscribe(() =>
        this.currentStep.set(TwoFaDeviceVerificationStepEnum.QR)
      );
  }

  onShowCopyToasty() {
    this.matSnackBar.open(
      this.#translateService.instant(
        'settings_page_two_factor_auth_copy_toasty'
      )
    );
  }

  onVerify() {
    this.verificationCodeControl.markAsTouched();

    if (this.verificationCodeControl.valid) {
      const id = this.authService.decodedUserAccount
        ? this.authService.decodedUserAccount.id
        : this.authStore.authUser().id;
      const payload = {
        verificationCode: this.verificationCodeControl.value.trim(),
        tfaType: VerificationOptionEnum.App,
        secret: this.authStore.tfaAccountInfo().id
      };

      this.authStore.verifyCode(payload);

      this.codeVerificationCallState$
        .pipe(
          filter(
            (callState) =>
              callState !== CallStateEnum.Loading &&
              callState !== CallStateEnum.Init
          ),
          take(3)
        )
        .subscribe((callState) => {
          if (callState === CallStateEnum.Loaded) {
            this.currentStep.set(
              TwoFaDeviceVerificationStepEnum.AssociateDevice
            );
          } else if (callState === CallStateEnum.Error) {
            this.verificationCodeControl.setErrors({
              wrongSecurityCode: true
            });
          }
        });
    }
  }

  onAssociateDevice() {
    const id = this.authService.decodedUserAccount
      ? this.authService.decodedUserAccount.id
      : this.authStore.authUser().id;
    this.authStore.updateDeviceStatusToVerified({
      id,
      isLogin: this.dialogData.isLogin,
      jwtToken: this.authStore.authUser().token
    });

    this.updateDeviceStatusToVerifiedCallState$
      .pipe(
        filter((callState) => callState === CallStateEnum.Loaded),
        take(1)
      )
      .subscribe(() => this.matDialogRef.close(true));
  }

  onBackOrCancel() {
    if (this.dialogData.isLogin) {
      this.currentStep.set(TwoFaDeviceVerificationStepEnum.QR);
      this.verificationCodeControl.reset();
    } else {
      this.matDialogRef.close();
    }
  }
}
