import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthStore } from '@app/store/auth/auth.store';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '@models/language.model';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  authStore = inject(AuthStore);
  document = inject(DOCUMENT);
  matDialog = inject(MatDialog);

  constructor(private translateService: TranslateService) {
    this.translateService.use(LanguageEnum.English);
  }

  ngOnInit() {
    const preConnectLink = this.document.getElementById(
      'pre-connect-link'
    ) as HTMLLinkElement;
    if (preConnectLink) {
      preConnectLink.href = environment.mediaUrl;
    }
  }
}
