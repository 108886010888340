import {
  patchState,
  signalStoreFeature,
  withMethods,
  withState
} from '@ngrx/signals';

export function withProtectedPatchState<T>() {
  return signalStoreFeature(
    withState({}),
    withMethods((_store) => ({
      setProtectedPatchState: (patch: Partial<T>) => patchState(_store, patch)
    }))
  );
}
