import { inject, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IErrorMessage } from '@models/error-message.model';
import { format } from 'date-fns';

@Pipe({ name: 'errorMessage', standalone: true })
export class FormErrorMessagePipe implements PipeTransform {
  #translateService = inject(TranslateService);

  transform(
    errors: ValidationErrors,
    overrideErrMessages?: IErrorMessage,
    dateFormat = 'MM-dd-yyy HH:mm:ss a'
  ): string {
    if (!errors) {
      return '';
    }

    const errorKey = Object.keys(errors)[0];
    const pattern =
      typeof errors[errorKey] === 'object' ? errors[errorKey] : null;
    const translationKey =
      overrideErrMessages && overrideErrMessages[errorKey]
        ? overrideErrMessages[errorKey]
        : `validation_${errorKey}`;

    if (typeof pattern === 'object' && pattern !== null) {
      for (const key in pattern) {
        if (pattern[key] instanceof Date) {
          pattern[key] = format(pattern[key], dateFormat);
        }
      }
    }

    return this.#translateService.instant(translationKey, pattern);
  }
}
